import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Facebook from "../../../../assets/images/facebook-512.svg";
import { useSelector } from "react-redux";

const ContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);
  console.log("appDetails", appDetails);
  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="container">
          <h2 className="sectionTitle">Contact Us</h2>
          <div className="payment-info">
            <div class="social-icons contact-social">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ContactUs;
